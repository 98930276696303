<template>
  <client-only>
    <div
      v-click-away="away"
      v-bind="$attrs"
      :class="mainStore.locale == 'ar' ? 'directionltr mr-2' : ''"
      @keydown.escape="isOpen = false"
      class="relative text-start"
    >
      <div class="relative z-10">
        <button
          v-if="!hasSlot('button-content')"
          @click.prevent="onClickOpen"
          v-text="props.text"
        />
        <slot
          v-else
          :onClickOpen="onClickOpen"
          :isOpen="isOpen"
          name="button-content"
        />
      </div>
      <transition
        enter-active-class="transition duration-100 ease-out transform"
        enter-from-class="scale-95 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in transform"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 opacity-0"
      >
        <div
          v-show="isOpen && !props.disabled"
          style="box-shadow: 0px 6px 24px 0px rgba(148, 149, 151, 0.24)"
          :class="[
            `${deviceSize} ${alignPositionClass} ${alignClass} ${dropdownMargin} ${widthClass} rounded-md shadow-lg overflow-hidden transform z-30`,
          ]"
        >
          <div
            class="py-1 overflow-hidden overflow-y-auto bg-white rounded-md shadow-lg overscroll-contain"
            :class="overflowClass"
          >
            <slot :onClickClose="onClickClose" :onClickOpen="onClickOpen" />
          </div>
        </div>
      </transition>
    </div>
  </client-only>
</template>

<script setup>
import { useLocaleStore } from '@/store/locales'
import { useMainStore } from '~/store'

const props = defineProps({
  name: {
    type: String,
    default: 'header',
  },
  text: {
    default: 'Dropdown',
    type: String,
  },
  position: {
    default: 'top',
    type: String,
  },
  align: {
    default: 'right',
    type: String,
  },
  width: {
    default: 'default',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  dropdownMargin: {
    default: 'mt-2',
    type: String,
  },
  dropdownType: {
    default: '',
    type: String,
  },
  hasOverflowHidden: {
    default: true,
    type: Boolean,
  },
})

const route = useRoute()
const router = useRouter()
const mainStore = useMainStore()
const store = useLocaleStore()
const slots = useSlots()
const emit = defineEmits()

const isOpen = ref(false)

const overflowClass = computed(() => {
  if (props.hasOverflowHidden) {
    return 'overflow-hidden overflow-y-auto'
  }
  return ''
})

const overflowParentClass = computed(() => {
  if (props.hasOverflowHidden) {
    return 'overflow-hidden'
  }
  return ''
})

const widthClass = computed(() => {
  const classesMap = {
    default: 'w-56',
    lg: props.name === 'footer' ? 'w-full sm:w-72' : 'w-auto sm:w-72',
    xl:
      props.name === 'footer'
        ? 'w-[92vw] sm:w-[22.5rem]'
        : 'w-auto sm:w-[22.5rem]',
    auto: 'w-auto',
  }

  return classesMap[props.width]
})

const alignPositionClass = computed(() => {
  const classesMap = {
    top: 'top-8',
    bottom: 'bottom-14',
  }

  return classesMap[props.position]
})

const alignClass = computed(() => {
  const classesMap = {
    right:
      props.dropdownType === 'locales' && props.name === 'headermobile'
        ? 'origin-top-left left-0 ltr:md:left-auto md:right-0'
        : 'origin-top-right rtl:md:left-0  ltr:md:right-0',
    left: 'origin-top-left ltr:left-0 rtl:left-auto md:right-0',
  }

  return classesMap[props.align]
})

const deviceSize = computed(() => {
  if (props.name === 'headermobile') {
    return 'fixed sm:absolute inset-x-0  mx-4 md:mx-0'
  }
  return 'absolute'
})

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    away()
  },
  { deep: true }
)

watch(isOpen, (newValue, oldValue) => {
  // store.handleClickOutside(newValue);
})

onMounted(() => {
  // this.$bus.$on('dropdown:close', () => (isOpen.value = false))
})

function hasSlot(name = 'default') {
  return !!slots[name] || !!slots.default[name]
}
function away(onOpen) {
  if (onOpen) {
    isOpen.value = false
    emit('click-outside', false)
  }
}
function onClickOpen() {
  if (!props.disabled) {
    isOpen.value = !isOpen.value
  }
}
function onClickClose() {
  if (!props.disabled) {
    isOpen.value = false
  }
}
</script>
<style scoped>
@media (max-width: 767px) {
  .w-locale-fix {
    width: calc(100vw - 32px);
  }
}
</style>
